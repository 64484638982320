import { Suspense } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/Home";
import DownloadYoutubeVideo from "./pages/DownloadYoutubeVideo";
import DownloadTiktokVideo from "./pages/DownloadTiktokVideo";
import DownloadFacebookVideo from "./pages/DownloadFacebookVideo";

export default function App() {
  return (
    <Router>
      <Suspense fallback={""}>
        <Routes>
          <Route path="/" element={<Home />} />

          <Route
            path="download-youtube-video"
            element={<DownloadYoutubeVideo />}
          />

          <Route
            path="download-facebook-video"
            element={<DownloadFacebookVideo />}
          />

          <Route
            path="download-tiktok-video"
            element={<DownloadTiktokVideo />}
          />
        </Routes>
      </Suspense>
    </Router>
  );
}
