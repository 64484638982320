import { Accordion, Button, TextInput } from "flowbite-react";
import { useState } from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import InstructionModal from "../components/InstructionModal";
import VideoListSection from "../components/VideoListSection";
import { useFacebook } from "../hooks/useFacebook";
import { VideoDownloadUrl } from "../types/VideoDownloadUrlResponse";

export default function DownloadFacebookVideo() {
  const [url, setUrl] = useState<string>();
  const { isLoading, getFacebookLinks, videos } = useFacebook();
  const [selectedVideo, setSelectedVideo] = useState<VideoDownloadUrl>();

  const getLinks = async () => {
    if (!url) {
      return;
    }

    getFacebookLinks(url);
  };

  return (
    <>
      <Header />

      <section className="bg-white dark:bg-gray-900">
        <div className="grid py-8 px-4 mx-auto max-w-screen-xl">
          <h1 className="mb-4 text-xl font-extrabold leading-none md:text-3xl xl:text-4xl dark:text-white">
            Download High-Quality Facebook Videos
          </h1>

          <div className="w-full">
            <div className="grid grid-cols-6 gap-2 mt-8">
              <TextInput
                id="url"
                placeholder="https://www.facebook.com/100006227642443/videos/pcb.635867688419131/1689059748224887"
                required
                sizing="lg"
                className="col-span-4 md:col-span-5"
                onChange={(event) => setUrl(event.target.value)}
              />

              <Button
                color="success"
                onClick={() => getLinks()}
                disabled={isLoading}
                isProcessing={isLoading}
                className="col-span-2 md:col-span-1"
              >
                Get links
              </Button>
            </div>
          </div>
        </div>
      </section>

      {videos && (
        <VideoListSection videos={videos} setSelectedVideo={setSelectedVideo} />
      )}

      <section className="bg-white dark:bg-gray-900 mt-60">
        <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl">
          <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
            <h2 className="mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">
              FAQs
            </h2>

            <Accordion>
              <Accordion.Panel>
                <Accordion.Title>What is downloadvideo.info?</Accordion.Title>
                <Accordion.Content>
                  <div className="mb-4 text-gray-500 dark:text-gray-400">
                    <p className="mb-4">
                      Downloadvideo.info is designed for the purpose of easily
                      and conveniently downloading online videos or music from
                      various websites and platforms. It offers a user-friendly
                      solution to allow users to fetch videos or music from the
                      internet with a single click, making it simple to save
                      content for offline viewing or listening.
                    </p>

                    <p className="mb-4">
                      Experience top-notch video quality with our online
                      Facebook downloader, which converts videos to MP4 format.
                      This versatile tool extends beyond Facebook, allowing you
                      to save content from TikTok, Instagram, Facebook, and
                      more.
                    </p>

                    <p>
                      Customize your video resolution, choosing from SD, HD,
                      FullHD, 2K, and 4K - even matching the original 1080p if
                      you prefer.
                    </p>
                  </div>
                </Accordion.Content>
              </Accordion.Panel>

              <Accordion.Panel>
                <Accordion.Title>
                  How to download Facebook video with downloadvideo.info?
                </Accordion.Title>
                <Accordion.Content>
                  <div className="mb-2 text-gray-500 dark:text-gray-400">
                    <ol>
                      <li className="pt-4">
                        1. Open Facebook and copy the video URL you want to
                        download.
                      </li>
                      <li className="pt-4">
                        2. Paste the video URL in the Search box, click "Get
                        links".
                      </li>
                      <li className="pt-4">
                        3. Select the Video or Audio quality you need and click
                        the "Download" button
                      </li>
                      <li className="pt-4">
                        4. After the video URL opened in new tab, click to 3
                        dot, then select "Download".
                      </li>
                      <li className="pt-4">
                        5. Once the video is downloaded, you can play it
                        whenever and wherever you want.
                      </li>
                    </ol>
                  </div>
                </Accordion.Content>
              </Accordion.Panel>
            </Accordion>
          </div>
        </div>
      </section>

      <section className="bg-white dark:bg-gray-900">
        <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
            <h2 className="mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">
              Download High-Quality Facebook Videos
            </h2>

            <p className="mb-4">
              Experience top-notch video quality with our online Facebook
              downloader, which converts videos to MP4 format. This versatile
              tool extends beyond Facebook, allowing you to save content from
              TikTok, Instagram, Facebook, and more.
            </p>

            <p>
              Customize your video resolution, choosing from SD, HD, FullHD, 2K,
              and 4K - even matching the original 1080p if you prefer.
            </p>
          </div>

          <div className="grid grid-cols-2 gap-4 mt-8">
            <img
              className="w-full rounded-lg"
              src={process.env.PUBLIC_URL + "/images/video-720.jpeg"}
              alt="office content 1"
            />
            <img
              className="mt-4 w-full rounded-lg lg:mt-10"
              src={process.env.PUBLIC_URL + "/images/video-1080.jpeg"}
              alt="office content 2"
            />
          </div>
        </div>
      </section>

      <section className="bg-gray-50 dark:bg-gray-800">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="mb-8 max-w-screen-md lg:mb-16">
            <h2 className="mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">
              How to download Facebook video with downloadvideo.info?
            </h2>

            <div className="text-gray-500 sm:text-xl dark:text-gray-400">
              <ol>
                <li className="pt-4">
                  1. Open Facebook and copy the video URL you want to download.
                </li>
                <li className="pt-4">
                  2. Paste the video URL in the Search box, click "Get links".
                </li>
                <li className="pt-4">
                  3. Select the Video or Audio quality you need and click the
                  "Download" button
                </li>
                <li className="pt-4">
                  4. After the video URL opened in new tab, click to 3 dot, then
                  select "Download".
                </li>
                <li className="pt-4">
                  5. Once the video is downloaded, you can play it whenever and
                  wherever you want.
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="mb-8 max-w-screen-md lg:mb-16">
            <h2 className="mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">
              Supported Browsers for Online Video Downloader
            </h2>

            <p className="text-gray-500 sm:text-xl dark:text-gray-400">
              Our online video downloader seamlessly works with a variety of web
              browsers, such as Google Chrome, Mozilla Firefox, Safari, Opera,
              and all Chromium-based browsers.
            </p>
          </div>
        </div>
      </section>

      <Footer />

      <InstructionModal
        video={selectedVideo}
        onClose={() => setSelectedVideo(undefined)}
      />
    </>
  );
}
