import { Button, Modal } from "flowbite-react";
import { VideoDownloadUrl } from "../types/VideoDownloadUrlResponse";

const handleDownload = async (url: string, fileName?: string) => {
  const link = document.createElement("a");
  link.download = fileName || "video-downloader";
  link.href = url;
  link.target = "_blank";

  link.click();
};

type Props = {
  video?: VideoDownloadUrl;
  onClose(): void;
};

export default function InstructionModal(props: Props) {
  const { onClose, video } = props;

  const onConfirm = () => {
    if (!video) {
      return;
    }

    handleDownload(video.url, video.fileName);
    onClose();
  };

  return (
    <Modal show={!!video} size="lg" popup position="center" onClose={onClose}>
      <Modal.Header />

      <Modal.Body>
        <div className="text-center">
          <h3 className="mb-5 text-lg font-normal text-gray-500 dark:text-gray-400">
            Download video instruction:
          </h3>

          <p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
            After video open in the new tab, you need to click to{" "}
            <strong>3 dot</strong> icon, and tap to &quot;
            <strong>Download</strong>&quot; video.
          </p>

          <p className="mt-2">
            <img
              src={process.env.PUBLIC_URL + "/images/guide-1.png"}
              alt="guide 1"
            />
          </p>
          <p className="mt-2">
            <img
              src={process.env.PUBLIC_URL + "/images/guide-2.png"}
              alt="guide 2"
            />
          </p>
        </div>
      </Modal.Body>

      <Modal.Footer className="justify-center">
        <Button color="success" onClick={onConfirm}>
          Okay, got it
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
