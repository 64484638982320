import Footer from "../components/Footer";
import Header from "../components/Header";

export default function DownloadTiktokVideo() {
  return (
    <>
      <Header />

      <section className="bg-white dark:bg-gray-900">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="mb-8 max-w-screen-md lg:mb-16">
            <h2 className="mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">
              Comming soon
            </h2>

            <p className="text-gray-500 sm:text-xl dark:text-gray-400">
              We are currently in development and will roll out as soon as
              possible. We appreciate your understanding, please check back
              later.
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
