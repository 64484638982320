export default function Header() {
  return (
    <header className="sticky top-0 z-10">
      <nav className="bg-gray-800 border-gray-200 px-4 lg:px-6 py-0 sm:py-2.5 dark:bg-gray-800">
        <div className="flex lg:flex-wrap justify-between items-center mx-auto max-w-screen-xl">
          <a href="/" className="flex items-center">
            <img
              src={process.env.PUBLIC_URL + "/images/logo.png"}
              className="mr-3 h-6 sm:h-9"
              alt="Logo"
            />

            <span className="self-center text-xl font-semibold whitespace-nowrap text-gray-100 hidden lg:inline-block">
              DownloadVideo
            </span>
          </a>

          <div className="justify-between items-center" id="mobile-menu-2">
            <ul className="flex flex-row font-medium lg:space-x-8 mt-0">
              <li className="w-100 md:w-auto text-center md:text-left">
                <a
                  href="/download-youtube-video"
                  className="block py-2 pr-4 pl-3 text-gray-200 lg:hover:text-gray-400 lg:p-0 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent"
                  aria-current="page"
                >
                  Download YOUTUBE{" "}
                  <span className="hidden lg:inline-block">video</span>
                </a>
              </li>

              <li className="w-100 md:w-auto text-center md:text-left">
                <a
                  href="/download-facebook-video"
                  className="block py-2 pr-4 pl-3 text-gray-200 lg:hover:text-gray-400 lg:p-0 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent"
                  aria-current="page"
                >
                  Download FACEBOOK{" "}
                  <span className="hidden lg:inline-block">video</span>
                </a>
              </li>

              <li className="w-100 md:w-auto text-center md:text-left">
                <a
                  href="/download-tiktok-video"
                  className="block py-2 pr-4 pl-3 text-gray-200 lg:hover:text-gray-400 lg:p-0 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent"
                  aria-current="page"
                >
                  Download TIKTOK{" "}
                  <span className="hidden lg:inline-block">video</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}
