import Footer from "../components/Footer";
import Header from "../components/Header";

const features = [
  {
    name: "Fast Downloads",
    description:
      "High-speed downloads for quick access to your favorite videos.",
    icon: (
      <svg
        className="w-5 h-5 text-green-500 lg:w-6 lg:h-6 dark:text-primary-300"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11.707 4.707a1 1 0 00-1.414-1.414L10 9.586 8.707 8.293a1 1 0 00-1.414 0l-2 2a1 1 0 101.414 1.414L8 10.414l1.293 1.293a1 1 0 001.414 0l4-4z"
          clipRule="evenodd"
        ></path>
      </svg>
    ),
  },
  {
    name: "Multi-Platform Support",
    description:
      "Download videos from popular platforms like YouTube, Facebook, TikTok, and more.",
    icon: (
      <svg
        className="w-5 h-5 text-green-500 lg:w-6 lg:h-6 dark:text-primary-300"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M10.394 2.08a1 1 0 00-.788 0l-7 3a1 1 0 000 1.84L5.25 8.051a.999.999 0 01.356-.257l4-1.714a1 1 0 11.788 1.838L7.667 9.088l1.94.831a1 1 0 00.787 0l7-3a1 1 0 000-1.838l-7-3zM3.31 9.397L5 10.12v4.102a8.969 8.969 0 00-1.05-.174 1 1 0 01-.89-.89 11.115 11.115 0 01.25-3.762zM9.3 16.573A9.026 9.026 0 007 14.935v-3.957l1.818.78a3 3 0 002.364 0l5.508-2.361a11.026 11.026 0 01.25 3.762 1 1 0 01-.89.89 8.968 8.968 0 00-5.35 2.524 1 1 0 01-1.4 0zM6 18a1 1 0 001-1v-2.065a8.935 8.935 0 00-2-.712V17a1 1 0 001 1z"></path>
      </svg>
    ),
  },
  {
    name: "Video Quality Options",
    description:
      "Choose from various video quality options, including HD, Full HD, and more.",
    icon: (
      <svg
        className="w-5 h-5 text-green-500 lg:w-6 lg:h-6 dark:text-primary-300"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M6 6V5a3 3 0 013-3h2a3 3 0 013 3v1h2a2 2 0 012 2v3.57A22.952 22.952 0 0110 13a22.95 22.95 0 01-8-1.43V8a2 2 0 012-2h2zm2-1a1 1 0 011-1h2a1 1 0 011 1v1H8V5zm1 5a1 1 0 011-1h.01a1 1 0 110 2H10a1 1 0 01-1-1z"
          clipRule="evenodd"
        ></path>
        <path d="M2 13.692V16a2 2 0 002 2h12a2 2 0 002-2v-2.308A24.974 24.974 0 0110 15c-2.796 0-5.487-.46-8-1.308z"></path>
      </svg>
    ),
  },
  {
    name: "No Software Installation",
    description:
      "Download videos directly from your web browser, no need for additional software.",
    icon: (
      <svg
        className="w-5 h-5 text-green-500 lg:w-6 lg:h-6 dark:text-primary-300"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M8.433 7.418c.155-.103.346-.196.567-.267v1.698a2.305 2.305 0 01-.567-.267C8.07 8.34 8 8.114 8 8c0-.114.07-.34.433-.582zM11 12.849v-1.698c.22.071.412.164.567.267.364.243.433.468.433.582 0 .114-.07.34-.433.582a2.305 2.305 0 01-.567.267z"></path>
        <path
          fillRule="evenodd"
          d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-13a1 1 0 10-2 0v.092a4.535 4.535 0 00-1.676.662C6.602 6.234 6 7.009 6 8c0 .99.602 1.765 1.324 2.246.48.32 1.054.545 1.676.662v1.941c-.391-.127-.68-.317-.843-.504a1 1 0 10-1.51 1.31c.562.649 1.413 1.076 2.353 1.253V15a1 1 0 102 0v-.092a4.535 4.535 0 001.676-.662C13.398 13.766 14 12.991 14 12c0-.99-.602-1.765-1.324-2.246A4.535 4.535 0 0011 9.092V7.151c.391.127.68.317.843.504a1 1 0 101.511-1.31c-.563-.649-1.413-1.076-2.354-1.253V5z"
          clipRule="evenodd"
        ></path>
      </svg>
    ),
  },
  {
    name: "Free to Use",
    description: "No cost or subscription required for basic features.",
    icon: (
      <svg
        className="w-5 h-5 text-green-500 lg:w-6 lg:h-6 dark:text-primary-300"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M7 3a1 1 0 000 2h6a1 1 0 100-2H7zM4 7a1 1 0 011-1h10a1 1 0 110 2H5a1 1 0 01-1-1zM2 11a2 2 0 012-2h12a2 2 0 012 2v4a2 2 0 01-2 2H4a2 2 0 01-2-2v-4z"></path>
      </svg>
    ),
  },
  {
    name: "No Watermarks",
    description: "Enjoy downloaded videos without annoying watermarks.",
    icon: (
      <svg
        className="w-5 h-5 text-green-500 lg:w-6 lg:h-6 dark:text-primary-300"
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M11.49 3.17c-.38-1.56-2.6-1.56-2.98 0a1.532 1.532 0 01-2.286.948c-1.372-.836-2.942.734-2.106 2.106.54.886.061 2.042-.947 2.287-1.561.379-1.561 2.6 0 2.978a1.532 1.532 0 01.947 2.287c-.836 1.372.734 2.942 2.106 2.106a1.532 1.532 0 012.287.947c.379 1.561 2.6 1.561 2.978 0a1.533 1.533 0 012.287-.947c1.372.836 2.942-.734 2.106-2.106a1.533 1.533 0 01.947-2.287c1.561-.379 1.561-2.6 0-2.978a1.532 1.532 0 01-.947-2.287c.836-1.372-.734-2.942-2.106-2.106a1.532 1.532 0 01-2.287-.947zM10 13a3 3 0 100-6 3 3 0 000 6z"
          clipRule="evenodd"
        ></path>
      </svg>
    ),
  },
];

export default function Home() {
  return (
    <>
      <Header />

      <section className="bg-white dark:bg-gray-900">
        <div className="grid py-8 px-4 mx-auto max-w-screen-xl lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
          <div className="place-self-center mr-auto lg:col-span-7">
            <h1 className="mb-4 max-w-2xl text-4xl font-extrabold leading-none md:text-5xl xl:text-6xl dark:text-white">
              Download High-Quality Videos from Youtube, Facebook, Tiktok
              anytime and anywhere.
            </h1>

            <p className="mb-6 max-w-2xl font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">
              Are you weary of the never-ending hunt for software to download
              online videos and music seamlessly? Your search comes to an end
              with our downloadvideo.info downloader - the free, one-click
              solution you've been longing for!
            </p>

            <a
              href="/download-youtube-video"
              className="inline-flex justify-center items-center mr-4 mt-4 w-full sm:w-auto py-3 px-5 text-base font-medium text-center text-white rounded-lg hover:bg-red-400 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800 bg-red-500"
            >
              Download Youtube Video
            </a>

            <a
              href="/download-facebook-video"
              className="inline-flex justify-center items-center mr-4 mt-4 w-full sm:w-auto py-3 px-5 text-base font-medium text-center text-white rounded-lg hover:bg-blue-700 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800 bg-blue-800"
            >
              Download Facebook Video
            </a>

            <a
              href="/download-tiktok-video"
              className="inline-flex justify-center items-center mr-4 mt-4 w-full sm:w-auto py-3 px-5 text-base font-medium text-center text-white rounded-lg bg-slate-900 border-gray-300 hover:bg-slate-800 focus:ring-4 focus:ring-gray-100 dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:ring-gray-800"
            >
              Download Tiktok Video
            </a>
          </div>

          <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
            <img
              src={process.env.PUBLIC_URL + "/images/banner.svg"}
              alt="mockup"
              className="rounded-lg"
            />
          </div>
        </div>
      </section>

      <section className="bg-gray-50 dark:bg-gray-800">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="mb-8 max-w-screen-md lg:mb-16">
            <h2 className="mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">
              Downloadvideo.info designed for easy online video and music
              downloads, just as you need.
            </h2>

            <p className="text-gray-500 sm:text-xl dark:text-gray-400">
              Downloadvideo.info is designed for the purpose of easily and
              conveniently downloading online videos or music from various
              websites and platforms. It offers a user-friendly solution to
              allow users to fetch videos or music from the internet with a
              single click, making it simple to save content for offline viewing
              or listening.
            </p>
          </div>

          <div className="space-y-8 md:grid md:grid-cols-2 lg:grid-cols-3 md:gap-12 md:space-y-0">
            {features.map((feature) => (
              <div key={feature.name}>
                <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-primary-100 lg:h-12 lg:w-12 dark:bg-primary-900">
                  {feature.icon}
                </div>

                <h3 className="mb-2 text-xl font-bold dark:text-white">
                  {feature.name}
                </h3>

                <p className="text-gray-500 dark:text-gray-400">
                  {feature.description}
                </p>
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="bg-white dark:bg-gray-900">
        <div className="gap-16 items-center py-8 px-4 mx-auto max-w-screen-xl lg:grid lg:grid-cols-2 lg:py-16 lg:px-6">
          <div className="font-light text-gray-500 sm:text-lg dark:text-gray-400">
            <h2 className="mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">
              Download High-Quality Videos
            </h2>

            <p className="mb-4">
              Experience top-notch video quality with our online YouTube
              downloader, which converts videos to MP4 format. This versatile
              tool extends beyond YouTube, allowing you to save content from
              TikTok, Instagram, Facebook, and more.
            </p>

            <p>
              Customize your video resolution, choosing from SD, HD, FullHD, 2K,
              and 4K - even matching the original 1080p if you prefer.
            </p>
          </div>

          <div className="grid grid-cols-2 gap-4 mt-8">
            <img
              className="w-full rounded-lg"
              src={process.env.PUBLIC_URL + "/images/video-720.jpeg"}
              alt="office content 1"
            />
            <img
              className="mt-4 w-full rounded-lg lg:mt-10"
              src={process.env.PUBLIC_URL + "/images/video-1080.jpeg"}
              alt="office content 2"
            />
          </div>
        </div>
      </section>

      <section className="bg-gray-50 dark:bg-gray-800">
        <div className="py-8 px-4 mx-auto max-w-screen-xl sm:py-16 lg:px-6">
          <div className="mb-8 max-w-screen-md lg:mb-16">
            <h2 className="mb-4 text-4xl font-extrabold text-gray-900 dark:text-white">
              Supported Browsers for Online Video Downloader
            </h2>

            <p className="text-gray-500 sm:text-xl dark:text-gray-400">
              Our online video downloader seamlessly works with a variety of web
              browsers, such as Google Chrome, Mozilla Firefox, Safari, Opera,
              and all Chromium-based browsers.
            </p>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
