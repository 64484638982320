import { Table, Badge, Button } from "flowbite-react";
import {
  VideoDownloadUrl,
  VideoDownloadUrlResponse,
} from "../types/VideoDownloadUrlResponse";

type Props = {
  videos: VideoDownloadUrlResponse;
  setSelectedVideo(video: VideoDownloadUrl): void;
};

export default function VideoListSection(props: Props) {
  const { videos, setSelectedVideo } = props;

  return (
    <section className="bg-white dark:bg-gray-900">
      <div className="grid py-8 px-4 mx-auto max-w-screen-xl">
        <h2 className="text-xl font-extrabold">Video: {videos.title}</h2>

        <div className="lg:grid lg:grid-cols-3 gap-4 mt-4">
          <div className="text-center">
            <img
              className="w-full rounded-lg"
              src={
                videos.thumbnail ||
                process.env.PUBLIC_URL + "/images/video-720.jpeg"
              }
              alt="office content 1"
            />
            {videos.duration && <>Duration: {videos.duration}</>}
          </div>

          <div className="lg:col-span-2 mt-4 lg:mt-0">
            <Table className="table-fixed">
              <Table.Head>
                <Table.HeadCell className="bg-gray-200">
                  Filename
                </Table.HeadCell>
                <Table.HeadCell className="bg-gray-200">Type</Table.HeadCell>
                <Table.HeadCell className="bg-gray-200">Action</Table.HeadCell>
              </Table.Head>

              <Table.Body className="divide-y">
                {videos.urls.map((video) => (
                  <Table.Row className="bg-green-100 dark:border-gray-700 dark:bg-gray-800">
                    <Table.Cell className="whitespace-nowrap font-medium text-gray-900 dark:text-white px-2">
                      {video.fileName}
                      <p className="text-gray-400">
                        <small>({video.fileSize})</small>
                      </p>
                    </Table.Cell>

                    <Table.Cell className="px-2">
                      <Badge
                        color={video.type === "video" ? "success" : "warning"}
                        size="sm"
                        className="max-w-fit"
                      >
                        <span>{video.type.toUpperCase()}</span>
                      </Badge>
                    </Table.Cell>

                    <Table.Cell className="px-2">
                      <Button
                        size="sm"
                        className="min-w-full max-w-fit sm:max-w-0 bg-green-500 hover:bg-green-400"
                        onClick={() => {
                          setSelectedVideo(video);
                        }}
                      >
                        <p>Download ({video.fileType})</p>
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        </div>
      </div>
    </section>
  );
}
