import { useState } from "react";
import { apiInstance } from "../axiosInstance";
import { VideoDownloadUrlResponse } from "../types/VideoDownloadUrlResponse";

export const useYoutube = () => {
  const [isLoading, setLoading] = useState(false);
  const [videos, setVideos] = useState<VideoDownloadUrlResponse>();

  const getYoutubeLinks = async (url: string) => {
    setLoading(true);

    try {
      const videos = await apiInstance
        .get("/youtube-video-urls", {
          params: { url },
        })
        .then((res) => res.data);

      setVideos(videos);
    } catch (error: any) {
      console.log("error", error);
    } finally {
      setLoading(false);
    }
  };

  return {
    getYoutubeLinks,
    videos,
    isLoading,
  };
};
